import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Page, Section, Carousel3d } from 'src/components';
import paths from 'src/constants/paths';

const QUERY = graphql`
  query {
    allGraphCmsGuide {
      nodes {
        id
        title
        longTitle
        summary
        slug
        color
        icon {
          url
        }
      }
    }
  }
`;

/**
 *
 */
const GuidesPage = () => {
  const data = useStaticQuery(QUERY);
  const guides = data?.allGraphCmsGuide.nodes;

  return (
    <Page
      title="Personal Payroll Guide"
      path={paths.GUIDES}
      navBorder={false}
      hideBanner
      kw={[
        'guides',
        'resources',
        'how-to',
        'covid',
        'open enrollment',
        'help',
        'expert',
        'learn',
        'financial education',
        'georgia access',
        'open enrollment',
      ]}
    >
      <Section
        wide
        large
        creatour
        title="Personal Payroll Guide"
        subtitle="Everything you need to know about taxes, retirement, health coverage and savings."
      >
        <Carousel3d
          items={guides.map((guide) => ({
            title: guide.title,
            subtitle: guide.longTitle,
            color: guide.color,
            path: `${paths.GUIDES}/${guide.slug}`,
            icon: guide.icon?.url,
          }))}
        />
      </Section>
    </Page>
  );
};

export default GuidesPage;
